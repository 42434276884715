<template>
  <div class="vg_wrapper">
    <h1>This is an about page {{ id }}</h1>
  </div>
</template>

<script>
export default {
  name: 'About',
  components: {},
  data() {
    return {
      id: 0
    }
  },
  created() {
    this.id = this.$route.query.id;
  }
}
</script>